import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faWeixin } from '@fortawesome/free-brands-svg-icons/faWeixin';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';

const data = [
  {
    link: 'https://github.com/hephyrius',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://www.instagram.com/harnickkhera/',
    label: 'Instagram',
    icon: faInstagram,
  },
  {
    link: 'https://www.linkedin.com/in/harnickkhera',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'https://twitter.com/hephyrius',
    label: 'Twitter',
    icon: faTwitter,
  },
  {
    link: 'https://t.me/hephyrius',
    label: 'Telegram',
    icon: faTelegram,
  },
  {
    link: 'https://u.wechat.com/kEsAk4q6bgFAh-OO-goH91w?s=1',
    label: 'Wechat',
    icon: faWeixin,
  },
  {
    link: 'mailto:harnickk@gmail.com',
    label: 'Email',
    icon: faEnvelope,
  },
];

export default data;
