import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="Profile" />
      </Link>
      <header>
        <h2>Harnick Khera</h2>
        <p><a href="mailto:hephyrius@gmail.com">hephyrius@gmail.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Harnick (aka Hephyrius). I am currently a PhD Researcher at Queen Mary University of London, specializing in AI applied to acoustics. Previously, I was the technical co-founder and advisor at <a href="https://housecat.com/">Housecat</a>. I also worked as a smart contract developer at <a href="https://aura.finance/">Aura</a>, a Whitehat scholar at <a href="https://immunefi.com/">Immunefi</a>, and an intern at <a href="https://loopring.org/#/">Loopring</a>. Additionally, I have experience as a data scientist at <a href="https://www.gov.uk/government/organisations/defence-science-and-technology-laboratory/about">DSTL</a>.</p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Harnick Khera <Link to="/">hephyrius.com</Link>.</p>
    </section>
  </section>
);

export default SideBar;
